<!-- Fetch locations -->
<script>
  import { onMount } from "svelte";
  import Map from "./general/Map.svelte";
  import SearchResult from "./SearchResult.svelte";
  import { store_locations_all, store_locations_visible, store_locationfinder_env_vars } from "./store.js";

  // props
  export let mapname;
  export let locationfinderNodeRef;

  const plone_portal_url = document.body.getAttribute("data-portal-url");

  const api_url_locations = plone_portal_url ? `${plone_portal_url}/@locationfinder-locations?mapname=${mapname}` : `/++api++/@locationfinder-locations?mapname=${mapname}`;

  onMount(async function () {
    await fetch(api_url_locations, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      mode: "cors",
      // cache: 'default',
    })
      .then((response) => response.json())
      .then((locs) => {
        let features = locs.features;
        // Initialize stores
        $store_locations_all[mapname] = features;
        $store_locations_visible[mapname] = features;
      });
  });
</script>

<Map {mapname} {locationfinderNodeRef} />
<SearchResult {mapname} />
