<script>
  import Icon from "@iconify/svelte";
  import { onMount } from "svelte";
  import { scale } from "svelte/transition";
  import { flip } from "svelte/animate";
  import { SyncLoader } from "svelte-loading-spinners";
  import { store_expert, store_experts_all, store_experts_visible, store_location, store_locationfinder_env_vars, store_locations_all, store_locations_visible, store_message, store_searchstring_expert, store_searchstring_location, store_region } from "../store";
  import Image from "./Image.svelte";

  // props
  export let mapname;
  export let show_experts = true;

  // state
  let isLoading = false;
  let isMounted = false;
  let searchstringExpertRef;

  // TODO Make Regions translatable
  const language = "de";
  // TODO Make label_show_organisations configurable
  const label_show_organisations = $store_locationfinder_env_vars[mapname]["mapbox_show_experts"];

  // TODO menuregions according values in backend index
  const menuregions = $store_locationfinder_env_vars[mapname]?.region_mapping?.items ?? [];
  const menuregions_mapping = menuregions.reduce((accumulator, currentValue) => {
    accumulator[currentValue.token] = currentValue.titles;
    return accumulator;
  }, {});

  const expertsearch_api_base_url = document.body.getAttribute("data-portal-url");
  const api_url_experts = (expertsearch_api_base_url ? expertsearch_api_base_url : "/++api++") + `/@locationfinder-experts?mapname=${mapname}`;

  onMount(async function () {
    isLoading = true;
    if (show_experts) {
      const response = await fetch(api_url_experts, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        mode: "cors",
        // cache: 'default',
      })
        .then((response) => response.json())
        .then((jsn) => {
          // Initialize stores
          $store_experts_all[mapname] = { ...jsn };
          $store_experts_visible[mapname] = { ...jsn };

          isLoading = false;
        });
      isMounted = true;
      handleSearch(undefined, undefined, undefined, undefined, undefined);
    } else {
      console.info("No expert search");
    }
  });

  $: handleSearch($store_searchstring_expert[mapname], $store_region[mapname], $store_expert[mapname], $store_location[mapname], $store_searchstring_location[mapname]);

  function _isAllowedForSearchstring(allowedStrings, searchstring) {
    let isAllowed = false;
    allowedStrings.forEach((element) => {
      if (element.toLowerCase().includes(searchstring.toLowerCase())) {
        isAllowed = true;
      }
    });
    return isAllowed;
  }

  /**
   *
   * @param searchstring search for experts
   * @param region
   * @param expert
   * @param location
   */
  function handleSearch(searchstring_expert, region, expert, location, searchstring_location) {
    if (!isMounted) {
      return;
    }

    let visible_experts;

    // "Show locations" selects also one expert
    if (expert) {
      visible_experts = [expert];
    } else if (location) {
      visible_experts = [...$store_experts_all[mapname]?.items]?.filter((item) => (item.experts_location ? item.experts_location["@id"] == location.properties["@id"] : false));
    } else {
      visible_experts = [...$store_experts_all[mapname]?.items]?.filter(
        (item) =>
          (searchstring_expert ? item.last_name?.toLowerCase().includes(searchstring_expert.toLowerCase()) || item.first_name?.toLowerCase().includes(searchstring_expert.toLowerCase()) || item.email?.toLowerCase().includes(searchstring_expert.toLowerCase()) || item.competence?.toLowerCase().includes(searchstring_expert.toLowerCase()) || item.subjects?.join(", ").toLowerCase().includes(searchstring_expert.toLowerCase()) || item.experts_location?.title?.toLowerCase().includes(searchstring_expert.toLowerCase()) || _isAllowedForSearchstring(item.allowed_search_strings, searchstring_expert) : true) &&
          (region // Region selected
            ? item.experts_location?.region
              ? item.experts_location?.region.token === region
              : false
            : true) &&
          // if item is restricted to search strings,
          // then searchstring_expert must be given and it is included in item.allowed_search_strings
          // or searchstring_location must be given and included in item.allowed_search_strings
          // else true
          (item.allowed_search_strings.length > 0 ? (searchstring_expert?.length > 0 && _isAllowedForSearchstring(item.allowed_search_strings, searchstring_expert)) || (searchstring_location?.length > 0 && _isAllowedForSearchstring(item.allowed_search_strings, searchstring_location)) : true) &&
          (searchstring_location?.length > 0 ? (item.experts_location ? $store_locations_visible[mapname].map((el) => el.properties["@id"]).includes(item.experts_location["@id"]) : false) : true),
      );
    }
    $store_experts_visible[mapname].items = visible_experts;
    $store_experts_visible[mapname].items_total = visible_experts.length;
  }

  const handleClickRegion = (menuregion) => {
    $store_region[mapname] = menuregion !== "all" ? menuregion : null;
    $store_location[mapname] = null;
    $store_expert[mapname] = null;
  };

  const handleReset = (event) => {
    $store_searchstring_expert[mapname] = "";
    searchstringExpertRef.focus();
  };

  const textTruncate = (string, length) => {
    let trimmedString = string.length > length ? string.substring(0, length - 3) + "..." : string;
    return trimmedString;
  };

  function getLocation(id) {
    const locations_mapping = $store_locations_all[mapname].reduce((accumulator, currentValue) => {
      accumulator[currentValue.properties["@id"]] = currentValue;
      return accumulator;
    }, {});
    return locations_mapping[id];
  }

  const handleInput = () => {
    // $store_searchstring_location[mapname] = "";
    $store_location[mapname] = null;
    $store_expert[mapname] = null;
  };

  const showLocationsOfExpertOnMap = (expert) => {
    $store_expert[mapname] = expert;
    $store_searchstring_location[mapname] = "";
    // $store_region[mapname] = null;
    const location = getLocation(expert.experts_location["@id"]); // TODO
    if (location) {
      $store_location[mapname] = location;
    }
    $store_message = ["error", "Ort nicht gefunden"];
  };
</script>

<form class="form-expertsearch" on:submit|preventDefault>
  <div class="searchcard-wrapper">
    <div class="searchcard">
      <div class="searchcard_inner">
        <input class="search searchstring_expert" placeholder="Suche Experten" bind:this={searchstringExpertRef} bind:value={$store_searchstring_expert[mapname]} on:input|preventDefault={handleInput} />
        <a class="resetbutton" href="." title="Reset" on:click|preventDefault={handleReset}> <Icon icon="system-uicons:cross" width="20" /></a>
      </div>
    </div>
  </div>
  {#if menuregions.length > 0}
    <div class="region-buttons">
      {#each menuregions as menuregion}
        <input type="button" class="regionbutton" class:selected={$store_region[mapname] === menuregion.token || (!$store_region[mapname] && menuregion.token == "all")} on:click|preventDefault={() => handleClickRegion(menuregion.token)} value={menuregions_mapping[menuregion.token] ? (menuregions_mapping[menuregion.token][language] ?? menuregion.token) : menuregion.token} />
      {/each}
    </div>
  {/if}
</form>

<div class="debug">
  Anzahl: {$store_experts_visible[mapname]?.items_total || 0}<br />
  searchstring: {$store_searchstring_expert[mapname]}<br />
  region: {$store_region[mapname]}<br />
</div>

<div class="cards">
  {#each $store_experts_visible[mapname]?.items || [] as expert, i (expert["@id"])}
    <div class="card" transition:scale animate:flip={{ duration: 300 }}>
      <div class="cardinner">
        <div class="cardbgbox" />
        {#if true}
          <a class="cardimagebox" href={expert["@id"]}>
            <div class="portrait">
              <Image src={`${expert["@id"]}/@@images/image/mini?${new Date().valueOf()}`} />
            </div>
          </a>
        {/if}
        <div class="cardtextbox">
          <a href={expert["@id"]}>
            <div class="fullname">
              {expert.title || `${expert.first_name} ${expert.last_name}`}
            </div>
          </a>
          {#if expert.telnr}
            <div class="telephone">{expert.telnr}</div>
          {/if}
          {#if expert.alternativeEmail}
            <div class="email">
              <a href="mailto:{expert.alternativeEmail}">{expert.alternativeEmail}</a>
            </div>
          {:else if expert.email}
            <div class="email">
              <a href="mailto:{expert.email}">{expert.email}</a>
            </div>
          {/if}
          {#if expert.website}
            <div class="website">
              <a href={expert.website}>Website</a>
            </div>
          {/if}
          {#if label_show_organisations}
            <a href="." rel="noreferrer" class="linktoorganisations" on:click|preventDefault={() => showLocationsOfExpertOnMap(expert)}>{label_show_organisations}</a>
          {/if}
        </div>
        <div class="cardlabel">
          {#if expert.experts_location}
            <!-- TODO Organisation clickable: Show location on map (change searchstring to organisation) -->
            <div class="organisation">{expert.experts_location.title}</div>
            {#if expert.experts_location.region}
              <div class="region">
                {expert.experts_location.region.title}
              </div>
            {/if}
          {/if}
          {#if expert.competence}
            <div class="competence">
              {textTruncate(expert.competence, 90)}
            </div>
          {/if}
        </div>
      </div>
    </div>
  {:else}
    {#if !isLoading}
      <p>Keine Experten gefunden</p>
    {/if}
  {/each}
  {#if isLoading}
    <div class="spinner">
      <SyncLoader size="30" color="#007cbf" unit="px" duration="2s" />
    </div>
  {/if}
</div>
