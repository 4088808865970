<script>
  import Icon from '@iconify/svelte';
  import { store_location, store_locations_all, store_locations_visible, store_locationfinder_env_vars, store_mapboxgl, store_map_bounds, store_popup, store_searchstring_expert, store_searchstring_location, store_expert, store_region } from './store';
  import MiniScroller from './MiniScroller.svelte';

  // props
  export let mapname;

  // state
  let searchstringLocationRef;

  // TODO Selection of type of location (make types configurable. event handler)
  const optionsType = [
    {
      token: 'all',
      value: 'All',
    },
    {
      token: 'location',
      value: 'Location',
    },
    {
      token: 'person',
      value: 'Person',
    },
    {
      token: 'event',
      value: 'Event',
    },
  ];

  $: handleSearch($store_map_bounds[mapname], $store_searchstring_location[mapname], $store_region[mapname]);

  function handleSearch(bounds, searchstring, region) {
    if (searchstring) {
      $store_expert[mapname] = null;
    }

    // Remove popup from map if no location selected
    !$store_location[mapname] && $store_popup[mapname]?.remove();

    const visible_locations = ($store_locations_all[mapname] || []).filter((item) => (searchstring ? item.properties.name_d.toLowerCase().includes(searchstring.toLowerCase()) || item.properties.location?.toLowerCase().includes(searchstring.toLowerCase()) || item.properties.street_nr?.toLowerCase().includes(searchstring.toLowerCase()) || item.properties.postal_code?.toLowerCase().includes(searchstring.toLowerCase()) : true) && (region !== 'all' && region !== null ? item.properties.region === region : true));
    $store_locations_visible[mapname] = visible_locations;
  }

  const handleResetSearch = () => {
    $store_searchstring_location[mapname] = '';
    $store_location[mapname] = null;
    searchstringLocationRef.focus();
  };
  const handleResetExpert = () => {
    $store_expert[mapname] = null;
    searchstringLocationRef.focus();
  };

  const initSearchResults = (node) => {
    if ($store_mapboxgl[mapname] && $store_mapboxgl[mapname]['map']) {
      const mapboxgl = $store_mapboxgl[mapname]['mapboxgl'];

      // Create a popup, but don't add it to the map yet.
      $store_popup[mapname] = new mapboxgl.Popup({
        closeButton: false,
      });
    }
  };

  const handleHover = (location) => {
    let coordinates = location.geometry?.coordinates?.slice();
    let properties = location.properties;

    let popup = $store_popup[mapname];
    popup.setLngLat(coordinates).setHTML('<div id="mini-scroller"></div>').addTo($store_mapboxgl[mapname]['map']);
    new MiniScroller({
      target: popup.getElement().querySelector('#mini-scroller'),
      props: { location_properties: properties },
    });
  };

  const handleClick = (location) => {
    // Set location. If location already selected, then reset.
    $store_location[mapname] = $store_location[mapname]?.properties?.name_d == location.properties.name_d ? null : location;
    $store_searchstring_expert[mapname] = '';
    // $store_region[mapname] = null;
    $store_expert[mapname] = null;
  };

  const handleInput = () => {
    // $store_searchstring_expert[mapname] = "";
    $store_location[mapname] = null;
    $store_expert[mapname] = null;
  };
</script>

<div class="searchresults" use:initSearchResults>
  {#if $store_locationfinder_env_vars[mapname]?.show_location_search || $store_locationfinder_env_vars[mapname]?.show_type_selection}
    <div class="searchcard">
      {#if $store_locationfinder_env_vars[mapname]?.show_location_search}
        <div class="searchcard_inner">
          <input type="text" placeholder="Suche Ort" class="search searchstring_location" bind:this={searchstringLocationRef} bind:value={$store_searchstring_location[mapname]} on:input|preventDefault={handleInput} />
          <a class="resetbutton" href="." title="Reset" on:click|preventDefault={() => handleResetSearch()}>
            <Icon icon="system-uicons:cross" width="20" />
          </a>
        </div>
      {/if}
      <!-- {#if $store_expert[mapname]}
        <div class="location_search">
          <span
            >{$store_expert[mapname]
              ? `${$store_expert[mapname]["title"] || ""}${
                  $store_expert[mapname]["first_name"]
                } ${$store_expert[mapname]["last_name"]}`
              : ""}</span
          >
          <a
            class="resetbutton"
            href="."
            title="Reset"
            on:click|preventDefault={() => handleResetExpert()}
          >
            <Icon icon="system-uicons:cross" width="13" />
          </a>
        </div>
      {/if} -->
      {#if $store_locationfinder_env_vars[mapname]?.show_type_selection}
        <div class="location_search">
          <label for="locationtypes">Wähle Typ:</label>
          <select name="locationtypes" id="locationtypes">
            {#each optionsType as option}
              <option value={option.token}>{option.value}</option>
            {/each}
          </select>
        </div>
      {/if}
    </div>
  {/if}

  {#if $store_locationfinder_env_vars[mapname]?.show_cards}
    <div class="searchresults-inner">
      {#each $store_locations_visible[mapname] as location, i}
        {#if location.properties.name_d}
          <!-- {#if location.properties.location} -->
          {#if true}
            <!-- TODO types from vocabulary of backend -->
            <div class="card" class:selected={$store_location && $store_location[mapname]?.properties?.name_d == location.properties.name_d} class:hotline={location.properties.type === 'hotline'} class:event={location.properties.type === 'event'} class:person={location.properties.type === 'person'} class:location={location.properties.type === 'location'}>
              <a class="card-body" href="." on:mouseover={() => handleHover(location)} on:focus|preventDefault={() => {}} on:click|preventDefault={() => handleClick(location)}>
                {#if location.properties.type == 'event'}
                  <p>
                    <span class="date">TODO event 01.01.2021</span>
                  </p>
                {/if}
                <div class="title" id={location.properties.name_d}>
                  {location.properties.name_d}
                </div>
                <!-- TODO Zeige Pfarrei auf Karte -->
                <!-- <p><discreet><a href=".">Zeige auf der Karte</a></discreet></p> -->
              </a>
              <!-- (<a
                href={`${$store_locationfinder_env_vars[mapname].expertsearch_api_base_url}/${location.properties.path}`}
                target="_blank"
                rel="noreferrer">edit</a
              >) -->
            </div>
          {/if}
        {/if}
      {/each}
    </div>
  {/if}
</div>
